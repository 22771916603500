.App {
  text-align: center; }

html, body {
    height: 100%; }

.logo_login {

	margin: 0 auto; }
.card-dsf {
	background: #FFFFFF;
	border-radius: 5px;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
	padding: 30px;
	margin-top: 20px; }

.cabecera {
  height: auto;
  padding: 10px 3px;
  width: 100%; }

h4 {
	text-align: left;
	font-size: 1.2rem !important;
	margin-top: 10px !important; }

.cuerpo {
	padding-top: 5px; }

.cuadro_usuario {
	float: left;
	margin-bottom: 20px;
	width: 100%;
	border: none !important; }

.cuadro_usuario .card-body {
    padding: 0.5rem;
    background: #1f2630;
    color: white; }

.cuadro_usuario .card-body h6 {

    color: #e0e0e0 !important; }


.num_repre {
	font-size: 11px;
	color: silver; }
.contenido {
	padding-top: 165px; }

.leyenda {
	background-color: #ffffff;
	width: auto;
	border-radius: 3px;
	padding: 2px;

	.leyenda-item {
		padding: 5px;
		float: left; }
	.limpiar {
		clear: both; } }

.estado {
	color: #58a0c3;
	cursor: pointer;
	b {
		margin-right: 10px; } }
body {

	background-color: #F9F9F9 !important;
	background-color: #FBAB7E !important;
	background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%); }



.image-item {
  display: flex;
  margin: 10px 0; }

.image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px; }
.foto {
	width: 100%; }

.li_pedido {
	text-align: left;
	b {
		width: 100%;
		display: block; } }

.list-group {
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1); }

.form-control {
    background-color: #ffffff5e !important; }

.volver {
	float: left; }

.spinner-dsf {
	width: 56px;
	height: 56px;
	border-radius: 5px;
	background: white;
	animation: rotate 1.2s infinite ease-in-out;
	position: fixed;
	right: 0;
	left: 0;
	top: 200px;
	margin: 0 auto; }

@keyframes rotate {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg); }

	50% {
		transform: perspective(120px) rotateX(-180deg) rotateY(0deg); }

	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }

.foto-talon {
	max-width: 100%;
	display: block;
	margin: 0 auto; }

.bultos {
	float:left {}
	border-radius: 5px;
	width: 60px;
	height: 35px;
	background: #f0f0f0;
	padding: 5px;
	max-width: 100% {
    margin: 20px auto; } }

.estado {
	float:left {}
	border-radius: 5px;
	width: auto;
	height: 35px;
	background: #f0f0f0;
	padding: 5px;
	max-width: 100% {
    margin: 20px auto; } }

.direccion_listado {
	font-size: 11px;
	color: #505050; }

.entregado_ribbon::before,
.entregado_ribbon::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	border-color: transparent;
	border-style: solid; }

.entregado_ribbon {
	&::before {
		border-width: 1.5em; }
	&::after {
		border-width: 1.20em;
		border-left-color: #4caf50;
		border-top-color: #4caf50; } }


